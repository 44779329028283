import logo from './logo.svg';
import './App.css';

import { HashRouter, Route, Routes } from 'react-router-dom';
import Entry from './pages/Entry';
import {useEffect, useState} from "react";
import axios from "axios";
import Lobby from "./pages/Lobby";
import Quiz from "./pages/Quiz";
import Wyniki from "./pages/Wyniki";
import AdminSettings from "./pages/AdminSettings";
import AdminLogin from "./pages/AdminLogin";


function App() 
{
  /**
   * Czas do zakończenia się quizu schodzący z GET /api/lobby
   */
  const [ending, setEnding] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const [isMobile, setMobile] = useState(false);

  axios.defaults.withCredentials = true;

  const updateAspectRatio = () => {
    setMobile(window.innerWidth < window.innerHeight);
  }

  useEffect(() => {

    /** Mobile mode handler */
    updateAspectRatio();
    window.onresize = (e) => {
      updateAspectRatio();
    }

    /** Session handler */
    const localToken = localStorage.getItem("auth_key");
    if (localToken == null) {
      setLoaded(true);
      return;
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${localToken}`;
    if (window.location.hash != '#/admin') {
      window.location.hash = '#/lobby';
    }
    setLoaded(true);
  }, []);

  return !loaded ? <></> : (
    <>
      <Routes>
        <Route path="/" Component={() => <Entry isMobile={isMobile} />} />
        <Route path="/lobby" Component={() => <Lobby ending={ending} setEnding={setEnding} isMobile={isMobile} />} />
        <Route path="/quiz" Component={() => <Quiz ending={ending} setEnding={setEnding} isMobile={isMobile} />} />
        <Route path="/wyniki/:score/:time" Component={() => <Wyniki isMobile={isMobile} />} />
        <Route path="/wyniki" Component={() => <Wyniki isMobile={isMobile} />} />

        <Route path="/admin" Component={() => <AdminLogin isMobile={isMobile} />} />
        <Route path="/admin/settings" Component={() => <AdminSettings isMobile={isMobile} />} />
      </Routes>
    </>
  );
}

export default App;
