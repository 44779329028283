import {useEffect, useState} from "react";
import axios from "axios";
import GradientButton from "../components/GradientButton";

export default function Lobby ({ending, setEnding, isMobile})
{
	const [hours, setHours] = useState('00');
	const [minutes, setMinutes] = useState('00');
	const [seconds, setSeconds] = useState('00');
	const [ready, setReady] = useState(false);
	const [overTime, setOverTime] = useState(false);
	const [ig, setIg] = useState(null);


	const clock = function (lobby)
	{
		const start = new Date(lobby.starting)
		const intv = setInterval(() => {
			const current = (new Date());
			const diff =  (start.getTime() / 1000 - current.getTime() / 1000);

			if (diff < 0) {
				setReady(true);
				clearInterval(intv);
				return;
			}

			const conv = new Date(parseInt(diff) * 1000);

			setHours(conv.getUTCHours().toString().padStart(2, '0'));
			setMinutes(conv.getUTCMinutes().toString().padStart(2, '0'));
			setSeconds(conv.getUTCSeconds().toString().padStart(2, '0'));

		}, 1000);
	}

	useEffect(() => {
		axios.get('/api/lobby').then((resp) => {
			setIg(resp.data.ig);
			clock(resp.data);
			setEnding(resp.data.ending); // HACK: Zapisz ending w lobby, żeby nie robić zapytania

			const endingTime = new Date(resp.data.ending);
			const currentTime = new Date();

			if (endingTime.getTime() - currentTime.getTime() < 0) {
				setOverTime(true);
			}
		}).catch((err) => {
			console.log(err);
			// alert(`Błąd serwera: ${JSON.stringify(err)}`)
			window.location.hash = '#/';
		})
	}, []);


	const TimeWindow = function ({ time })
	{
		return (
			<div style={{
				width: isMobile ? '40px' : '53px', height: isMobile ? '60px' : '84px',
				borderRadius: '5px',
				backgroundColor: '#242424',
				display: 'flex', justifyContent: 'center', alignItems: 'center',
				fontFamily: 'Sarpanch',
				fontSize: isMobile ? '30px' : '40px',
				fontWeight: 500,
				color: '#fff'
			}}>
				{time}
			</div>
		)
	}

	const TimeSlice = function ({ title, left, right })
	{
		return (
			<div>
				<div style={{
					display: 'flex',
					gap: isMobile ? '6px' : '9px'
				}}>
					<TimeWindow time={left} />
					<TimeWindow time={right} />
				</div>
				<div style={{
					textAlign: 'center',
					marginTop: '15px',
					fontFamily: 'Sansation Light',
					color: '#fff',
					fontWeight: 300,
					fontSize: isMobile ? '14px' : '20px'
				}}>
					{title}
				</div>
			</div>
		)
	}

	return (
		<div style={{
			width: '100vw',
			height: '100vh',
			backgroundColor: '#111',
			backgroundImage: `url(bg_lobby${isMobile ? '_mob' : ''}.png)`,
			backgroundPosition: 'center bottom',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'center'
		}}>
			<div style={ isMobile ? {
				width: '100%', textAlign: 'center'
			} : {
				width: '90%'
			}}>
				<img src="/logo.svg" style={{ marginTop: isMobile ? '95px' : '75px', width: isMobile ? '231px' : '311px'}}/>
			</div>
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column'
			}}>
				<img src="/juz_w_wkrotce.svg" style={{ width: isMobile ? '318px' : '705px' }} />
				<div style={{
					width: isMobile ? '265px' : '429px',
					marginTop: '38px',
					font: 'Sansation Light',
					color: '#fff',
					fontSize: '18px',
					fontWeight: 300,
					textAlign: 'center'
				}}>
					Rozwiązujesz quiz jako <b>{ig}</b>, <span style={{color: 'rgb(238, 51, 51)'}}>kliknij Zaczynam, gdy licznik wskaże 0</span>, by rozpocząć!
				</div>
				<div style={{
					marginTop: '38px',
					display: 'flex',
					alignItems: 'center',
					gap: isMobile ? '22px' : '32px'
				}}>
					<TimeSlice title="godzin" left={hours.at(0)} right={hours.at(1)} />
					<TimeSlice title="minut" left={minutes.at(0)} right={minutes.at(1)} />
					<TimeSlice title="sekund" left={seconds.at(0)} right={seconds.at(1)} />
				</div>
				<GradientButton
					containerStyle={{marginTop: '52px'}}
					disabled={!ready}
					onClick={(e) => {
						if (!ready) {
							return;
						}
						window.location.hash = overTime ? '#/wyniki' : '#/quiz';
					}}
				>
					{overTime ? 'Zobacz wyniki' : 'Zaczynam'}
				</GradientButton>
				{!ready && (
					<div style={{
						fontFamily: 'Sansation Light',
						fontSize: isMobile ? '14px' : '20px',
						fontWeight: 600,
						color: '#fff',
						textDecoration: 'underline',
						marginTop: '25px'
					}}>
						Nie odświeżaj strony!
					</div>
				)}
			</div>
			<div>
			</div>
		</div>
	)
}