import { useState } from "react";
import axios from 'axios';
import { __server } from "../constants";
import GradientButton from "../components/GradientButton";
import Popup from "../components/Popup";


export default function AdminLogin ({isMobile})
{
  const [passw, setPassw] = useState('');

  const [popupPassw, setPopupPassw] = useState(false);

  const tbxWrapper = {
    width: isMobile ? '296px' : '390px',
    height: isMobile ? '58px' : '68px',
    marginTop: isMobile ? '40px' : '30px'
  };

  const tbxInner = {
    width: '100%',
    height: '58px',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'Sansation Light',
    fontSize: '20px',
    fontWeight: '300px',
    '::placeholder': {
      color: '#fff'
    }
  };

  const onSubmit = function (e)
  {
    e.preventDefault();

    axios.post('/api/admin', passw).then((resp) => {
      localStorage.setItem("admin_key", passw);
      window.location.hash = '#/admin/settings';
    }).catch((err) => {
      console.log(err);
      setPopupPassw(true);
    });

  }

  return (
    <>
      <Popup open={popupPassw} setOpen={setPopupPassw} title="Błąd" descr="Brak dostępu do konta administratora" />

      <div style={{
        backgroundColor: '#111',
        backgroundImage: `url(/bg_entry${isMobile ? '_mob' : ''}.png)`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <form style={{
          width: isMobile ? '296px' : '390px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }} onSubmit={onSubmit}>
          <img src="/logo.svg" style={{width: isMobile ? '231px' : '311px'}}/>
          <div style={{
            width: isMobile ? '265px' : '332px',
            marginTop: '65.5px',
            textAlign: 'center',
            color: '#fff',
            fontFamily: 'Sansation Light',
            fontSize: '20px',
            fontWeight: 300
          }}>
            PANEL ADMINISTRATORA
          </div>

          <div style={{
            backgroundImage: `url(/tbx_nick${isMobile ? '_mob' : ''}.png)`, ...tbxWrapper
          }}>
            <input
              value={passw}
              onChange={(e) => setPassw(e.target.value)}
              style={tbxInner}
              type="password"
              placeholder="Hasło"
            />
          </div>
          <button style={{
            marginTop: '30px',
            width: '241px',
            height: '68px',
            fontFamily: 'Sansation',
            fontWeight: 700,
            color: '#000',
            fontSize: '20px',
            borderRadius: '52px',
            background: 'linear-gradient(82deg, #4c0197 -13.64%, #fddf01 92.08%)',
            border: 'none',
            outline: 'none',
            cursor: 'pointer'
          }}>
            Zaloguj
          </button>
        </form>
      </div>
    </>
  )
}