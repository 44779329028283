import {useEffect, useState} from "react";
import axios from "axios";
import Popup from "../components/Popup";
import GradientButton from "../components/GradientButton";
import {Link} from "react-router-dom";

export default function AdminSettings ({isMobile})
{
	const [stats, setStats] = useState(null);

	const fetchStats = function ()
	{
		setStats(null);
		axios.get('/api/admin').then((resp) => {
			setStats(resp.data);
		}).catch((err) => {
			alert(`Nie udało się pobrać statystyk: ${JSON.stringify(err)}`);
		})
	}

	useEffect(() => {
		if (localStorage.getItem('admin_key') == null) {
			window.location.hash = '#/admin';
			return () => {};
		}

		fetchStats();

		axios.post('/api/admin', localStorage.getItem('admin_key')).then((resp) => {
			console.log(resp);
			setPin(resp.data.pin);
			const qsts = JSON.parse(resp.data.jsonQuestions);
			setQuestions(qsts);
			setEnding(resp.data.ending.split(':00+').at(0));
			setStarting(resp.data.starting.split(':00+').at(0));

			// decode correct answears
			const cbxs = Array(10).fill(null).map((n) => [false, false, false, false]);
			for (let i = 0; i < resp.data.correct.length; i++) {
				cbxs[i][resp.data.correct[i]] = true;
			}

			console.log(qsts);
			setCheckBoxes(cbxs);
		}).catch((err) => {
			console.log(err);
			alert(`Błąd serwera: ${JSON.stringify(err)}`);
		})
	}, []);


	const [err, setErr] = useState(null);
	const [checkBoxes, setCheckBoxes] = useState(Array(10).fill([false, false, false, false]));
	const [questions, setQuestions] = useState(Array(10).fill({question: '', answears: Array(4).fill('')}));
	const tr = ['A', 'B', 'C', 'D'];

	const [pin, setPin] = useState(null);
	const [starting, setStarting] = useState(null);
	const [ending, setEnding] = useState(null);

	const [disable, setDisable] = useState(false);

	const onUpdate = function (e)
	{
		if (!window.confirm("Czy aby na pewno chcesz zapisać zmiany? Zapis spowoduje zresetowanie konkursu (usunięcie starych wyników)")) {
			return;
		}

		const jsonQuestions = JSON.stringify(questions);
		const answears = checkBoxes.map((n) => n.indexOf(true));

		if (ending < starting) {
			setErr("Czas końca jest przed czasem początku");
			return;
		}

		if (answears.includes(-1)) {
			setErr(`Nie zaznaczono poprawnej odpowiedzi w pytaniu nr ${answears.indexOf(-1) + 1}`);
			return;
		}

		setDisable(true);
		axios.post('/api/core', {
			key: localStorage.getItem('admin_key'),
			core: {
				correct: answears,
				jsonQuestions: jsonQuestions,
				starting: starting + ':00+02:00',
				ending: ending + ':00+02:00',
				pin: pin
			}
		}).then((resp) => {
			setDisable(false);
			alert("Pomyślnie zakutalizowano zmiany");
		}).catch((err) => {
			setDisable(false);
			console.log(err);
			setErr(`Błąd serwera: ${JSON.stringify(err)}`)
		});

	}

	const styleSmallTbx = {
		backgroundColor: 'transparent',
		border: '1px solid #523867',
		color: '#fff',
		fontFamily: 'Sansation Light',
		fontSize: '20px',
		fontWeight: 300,
		borderRadius: '25px',
		outline: 'none',
		resize: 'none',
		boxSizing: 'border-box',
		padding: '10px',
		width: '300px'
	}

	return (
		<>
			<Popup setOpen={() => setErr(null)} open={err != null} descr={err} title="Wystąpił błąd" />

			<div style={{
				width: '100vw',
				height: '100vh',
				backgroundColor: '#111',
				zIndex: 9999,
				backgroundImage: `url(/bg_lobby${isMobile ? '_mob' : ''}.png)`,
				backgroundSize: 'contain',
				backgroundPosition: 'center bottom',
				backgroundRepeat: 'no-repeat',
				overflow: 'hidden'
			}}>
				<div style={{
					width: '100vw',
					height: '100vh',
					overflowY: 'scroll',
					overflowX: 'hidden'
				}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: isMobile ? 'center' : 'flex-start',
							width: isMobile ? '100%' : '90%',
							margin: 'auto',
							paddingTop: isMobile ? '95px' : '75px',
							flexDirection: isMobile ? 'column' : 'row'
						}}
					>
						<img src="/logo.svg" style={isMobile ? {width: '231px'} : {}}/>

						<div style={{
							display: 'flex', gap: isMobile ? '20px' : '24px', marginTop: isMobile ? '37px' : '0px'
						}}>
						</div>
					</div>
					<div style={{
						marginTop: isMobile ? '50px' : '100px',
						width: '100vw',
						display: 'flex', flexDirection: 'column', alignItems: 'center'
					}}>
						<div style={{
							fontFamily: 'Sansation',
							fontSize: isMobile ? '30px' : '40px',
							fontWeight: 700,
							color: '#fff',
							marginBottom: '40px'
						}}>
							Konfiugracja quizu:
						</div>
						<div style={{
							fontFamily: 'Sansation',
							fontSize: isMobile ? '16px' : '25px',
							color: '#fff',
							display: 'flex',
							flexDirection: 'column',
							gap: '30px'
						}}>

							<div style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: '20px',
								width: isMobile ? '300px' : '400px'
							}}>
								PIN:
								<input type="text" style={styleSmallTbx} value={pin} onChange={(e) => setPin(e.target.value)}/>
							</div>
							<div style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: '20px',
								width: isMobile ? '300px' : '400px'
							}}>
								Start:
								<input type="datetime-local" style={styleSmallTbx} value={starting}
											 onChange={(e) => setStarting(e.target.value)}/>
							</div>
							<div style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: '20px',
								width: isMobile ? '300px' : '400px'
							}}>
								Koniec:
								<input type="datetime-local" style={styleSmallTbx} value={ending}
											 onChange={(e) => setEnding(e.target.value)}/>
							</div>
						</div>
						<div style={{marginTop: '40px'}}>
							<Link to="/wyniki" style={{
								fontFamily: 'Sansation Light',
								fontSize: '16px',
								color: '#fff',
							}}>Zobacz wyniki quizu</Link>
						</div>
						<div style={{
							fontFamily: 'Sansation',
							fontSize: isMobile ? '30px' : '40px',
							fontWeight: 700,
							color: '#fff',
							marginBottom: '40px',
							marginTop: '60px'
						}}>
							Edytuj pytania:
						</div>
						{questions.map((question, key) => (
							<div>
								<div style={{
									width: isMobile ? '320px' : '960px', fontFamily: 'Sansation', color: '#fff',
									fontSize: isMobile ? '18px' : '32px', fontWeight: 400, textAlign: 'center'
								}}>
									<span style={isMobile ? {display: 'block', marginBottom: '30px'} : {}}>
										{key + 1}.
									</span>
									<textarea
										style={{
											backgroundColor: 'transparent',
											border: '1px solid #523867',
											width: isMobile ? '300px' : '850px',
											height: '120px',
											color: '#fff',
											fontFamily: 'Sansation Light',
											fontSize: '20px',
											fontWeight: 300,
											borderRadius: '25px',
											outline: 'none',
											resize: 'none',
											boxSizing: 'border-box',
											padding: '23px',
											marginLeft: '20px'
										}}
										value={question.question}
										onChange={(e) => {
											const qsts = [...questions];
											qsts[key].question = e.target.value;
											setQuestions(qsts);
										}}></textarea>
								</div>
								<div style={{
									width: isMobile ? '320px' : '834px',
									marginLeft: isMobile ? '0px' : '63px',
									display: 'flex',
									gap: isMobile ? '10px' : '42px',
									flexWrap: 'wrap',
									marginTop: isMobile ? '22px' : '59px',
									marginBottom: isMobile ? '50px' : '100px'
								}}>
									{question.answears.map((answ, ith) => (
										<div style={{
											width: isMobile ? '320px' : '396px',
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
											gap: '12px',
											fontFamily: 'Sansation Light',
											fontSize: isMobile ? '16px' : '24px',
											color: '#fff',
											fontWeight: 300,
											backgroundColor: 'transparent',
											borderRadius: '4px',
											padding: isMobile ? '16px 12px' : '0px 0px'
										}}>

											<img
												src={checkBoxes[key][ith] ? "/checked.svg" : "/unchecked.svg"}
												style={{width: isMobile ? '22px' : '38px', zIndex: '9999', cursor: 'pointer'}}
												onClick={(e) => {
													const newCheckboxes = [...checkBoxes];
													newCheckboxes[key] = [false, false, false, false];
													newCheckboxes[key][ith] = true;
													setCheckBoxes(newCheckboxes);
												}}
											/>
											{tr[ith]}.
											<input
												style={{
													backgroundColor: 'transparent',
													border: '1px solid #523867',
													width: '100%',
													color: '#fff',
													fontFamily: 'Sansation Light',
													fontSize: '20px',
													fontWeight: 300,
													borderRadius: '25px',
													outline: 'none',
													resize: 'none',
													boxSizing: 'border-box',
													padding: '10px',
												}}
												type='text'
												value={answ}
												onChange={(e) => {
													/**
													 * We are modyfing object questions[key].answear[ith]
													 */

													const answ = [...question.answears];
													answ[ith] = e.target.value;
													const newObj = {...questions[key], answears: answ};

													const qsts = [...questions];
													qsts[key] = newObj;

													console.log(qsts);
													setQuestions(qsts);
												}}/>
											<br/>
										</div>
									))}
								</div>
							</div>
						))}
						<div>
							<GradientButton
								containerStyle={{marginBottom: '52px'}}
								disabled={disable}
								onClick={onUpdate}
							>
								Zapisz zmiany
							</GradientButton>
						</div>
						<div style={{
							fontFamily: 'Sansation',
							color: '#fff',
							paddingBottom: '75px'
						}}>
							{stats == null ? (
								<>
									Ładowanie statystyk...
								</>
							) : (
								<>
									Sesje: {stats.sessions}, Rozpoczęte: {stats.started}, Ukończone: {stats.finished} -&nbsp;
									<u onClick={(e) => fetchStats()}>
										odśwież
									</u>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}