import {useEffect, useState} from "react";
import axios from "axios";
import GradientButton from "../components/GradientButton";

export default function Quiz ({ending, setEnding, isMobile})
{
	const [checkBoxes, setCheckBoxes] = useState(Array(10).fill([false, false, false, false]));
	const [lotteryActive, setLotteryActive] = useState(true);

	const [minutes, setMinutes] = useState('00');
	const [seconds, setSeconds] = useState('00');
	const [loading, setLoading] = useState(false);

	const [questions, setQuestions] = useState([]);
	const TimeWindow = function ({ time })
	{
		return (
			<div style={{
				width: isMobile ? '30px' : '53px', height: isMobile ? '50px' : '84px',
				borderRadius: '5px',
				backgroundColor: '#242424',
				display: 'flex', justifyContent: 'center', alignItems: 'center',
				fontFamily: 'Sarpanch',
				fontSize: isMobile ? '22px' : '40px',
				fontWeight: 500,
				color: '#fff'
			}}>
				{time}
			</div>
		)
	}

	const clock = function ()
	{
		const endingTime = new Date(ending);
		const intv = setInterval(() => {

			const currentTime = new Date();
			const diff = endingTime.getTime() / 1000 - currentTime.getTime() / 1000;

			if (diff < 0) {
				setMinutes('00');
				setSeconds('00');
				clearInterval(intv);
				setLotteryActive(false);
				return;
			}

			const conv = new Date(parseInt(diff) * 1000);

			setMinutes(conv.getUTCMinutes().toString().padStart(2, '0'));
			setSeconds(conv.getUTCSeconds().toString().padStart(2, '0'));

		}, 1000);
	}

	useEffect(() => {
		clock();
		axios.get('/api/quiz').then((resp) => {
			setQuestions(resp.data); // TODO: Add ending
		}).catch((err) => {
			console.log(err);
			// alert(`Błąd serwera: ${JSON.stringify(err)}`)
			window.location.hash = '#/wyniki';
		})
	}, []);

	const TimeSlice = function ({ title, left, right })
	{
		return (
			<div>
				<div style={{
					display: 'flex',
					gap: isMobile ? '6px' : '9px'
				}}>
					<TimeWindow time={left} />
					<TimeWindow time={right} />
				</div>
				<div style={{
					textAlign: 'center',
					marginTop: '15px',
					fontFamily: 'Sansation Light',
					color: '#fff',
					fontWeight: 300,
					fontSize: isMobile ? '12px' : '20px'
				}}>
					{title}
				</div>
			</div>
		)
	}

	const onFinish = function (e)
	{
		/**
		 * Zczytujemy wyniki do tablicy i wysyłamy na serwer
		 * chyba że już post faktum, to tylko na wyniki
		 * ... do przemyślenia
		 */

		const answ = checkBoxes.map((n) => n.indexOf(true));
		setLoading(true);
		axios.post('/api/quiz', answ).then((resp) => {
			setLoading(false);
			const time = resp.data.end - resp.data.start;
			window.location.hash = `#/wyniki/${resp.data.score}/${time}`;
		}).catch((err) => {
			setLoading(false);
			console.log(err);
			alert(`Czas odpowiedzi minął`);
			window.location.hash = '#/wyniki';
		})

	}

	const tr = ['A', 'B', 'C', 'D'];

	return (
		<>
			<div style={{
				width: '100vw',
				height: '100vh',
				backgroundColor: '#111',
				zIndex: 9999,
				backgroundImage: `url(/bg_lobby${isMobile ? '_mob' : ''}.png)`,
				backgroundSize: 'contain',
				backgroundPosition: 'center bottom',
				backgroundRepeat: 'no-repeat',
				overflow: 'hidden'
			}}>
				<div style={{
					width: '100vw',
					height: '100vh',
					overflowY: 'scroll',
					overflowX: 'hidden'
				}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: isMobile ? 'center' : 'flex-start',
							width: isMobile ? '100%' : '90%',
							margin: 'auto',
							paddingTop: isMobile ? '95px' : '75px',
							flexDirection: isMobile ? 'column' : 'row'
						}}
					>
						<img src="/logo.svg" style={isMobile ? {width: '231px'} : {}}/>

						<div style={{
							display: 'flex', gap: isMobile ? '20px' : '24px', marginTop: isMobile ? '37px' : '0px'
						}}>
							<TimeSlice left={minutes.at(0)} right={minutes.at(1)} title="minut"/>
							<TimeSlice left={seconds.at(0)} right={seconds.at(1)} title="sekund"/>
						</div>
					</div>
					<div style={{
						marginTop: isMobile ? '50px' : '100px',
						width: '100vw',
						display: 'flex', flexDirection: 'column', alignItems: 'center'
					}}>
						{questions.map((question, key) => (
							<div>
								<div style={{
									width: isMobile ? '320px' : '960px', fontFamily: 'Sansation', color: '#fff',
									fontSize: isMobile ? '18px' : '32px', fontWeight: 400, textAlign: 'center'
								}}>
									{key + 1}. {question.question}
								</div>
								<div style={{
									width: isMobile ? '320px' : '834px',
									marginLeft: isMobile ? '0px' : '63px',
									display: 'flex',
									gap: isMobile ? '10px' : '42px',
									flexWrap: 'wrap',
									marginTop: isMobile ? '22px' : '59px',
									marginBottom: isMobile ? '50px' : '100px'
								}}>
									{question.answears.map((answ, ith) => (
										<div style={{
											width: isMobile ? '320px' : '396px',
											display: 'flex',
											justifyContent: 'flex-start',
											alignItems: 'center',
											gap: '12px',
											fontFamily: 'Sansation Light',
											fontSize: isMobile ? '16px' : '24px',
											color: '#fff',
											fontWeight: 300,
											backgroundColor: isMobile ? '#242424' : 'transparent',
											borderRadius: '4px',
											padding: isMobile ? '16px 12px' : '0px 0px'
										}}>
											<img
												src={checkBoxes[key][ith] ? "/checked.svg" : "/unchecked.svg"}
												style={{width: isMobile ? '22px' : '38px', zIndex: '9999', cursor: 'pointer'}}
												onClick={(e) => {
													const newCheckboxes = [...checkBoxes];
													newCheckboxes[key] = [false, false, false, false];
													newCheckboxes[key][ith] = true;
													setCheckBoxes(newCheckboxes);
												}}
											/>
											{tr[ith]}. {answ} <br/>
										</div>
									))}
								</div>
							</div>
						))}
						<div>
							{questions.length != 0 && (
								<GradientButton
									onClick={onFinish}
									disabled={!lotteryActive || loading}
									containerStyle={{
										marginTop: '52px',
										marginBottom: '100px'
									}}
								>
									{lotteryActive ? 'Wyślij odpowiedzi' : 'Zobacz wyniki'}
								</GradientButton>
							)}
							{/*<div style={{*/}
							{/*	cursor: 'pointer',*/}
							{/*	marginTop: '52px',*/}
							{/*	width: '238px',*/}
							{/*	height: '68px',*/}
							{/*	display: 'flex',*/}
							{/*	justifyContent: 'center',*/}
							{/*	alignItems: 'center',*/}
							{/*	backgroundImage: lotteryActive ? 'url(/btn_start.png)' : 'url(/btn_start_disabled.png)',*/}
							{/*	backgroundSize: 'contain',*/}
							{/*	fontFamily: 'Sansation',*/}
							{/*	fontSize: '20px',*/}
							{/*	fontWeight: 400,*/}
							{/*	color: lotteryActive ? '#fff' : '#ffffff4c',*/}
							{/*	marginBottom: '100px'*/}
							{/*}} onClick={onFinish}>*/}
							{/*	{lotteryActive ? 'Wyślij odpowiedzi' : 'Zobacz wyniki'}*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}