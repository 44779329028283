import {useEffect, useState} from "react";

export default function Popup ({open, setOpen, title, descr})
{
	const [hazar, setHazard] = useState(false);

	useEffect(() => {
		if (open) {
			setInterval(() => {
				if (!hazar) {
					setOpen(false);
				} else {
					setHazard(false);
				}
			}, 5000);
		} else {
			setHazard(true);
		}
	}, []);

	return !open ? <></> : (
		<div style={{
			position: 'fixed', right: 'calc((100vw - 395px) / 2)', bottom: '4%',
			width: '395px', height: '84px',
			backgroundColor: '#242424', borderRadius: '5px',
			color: '#fff',
			display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '8px',
			boxSizing: 'border-box', padding: '0px 32px',
			cursor: 'pointer'
		}} onClick={(e) => setOpen(false)}>
			<div style={{
				fontFamily: 'Sansation', fontWeight: 700, fontSize: '16px',
				textAlign: 'center'
			}}>
				{title}
			</div>
			<div style={{
				fontFamily: 'Sansation Light', fontWeight: 300, fontSize: '14px',
				textAlign: 'center'
			}}>
				{descr}
			</div>
		</div>
	)
}