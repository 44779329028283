import { useState } from "react";
import axios from 'axios';
import { __server } from "../constants";
import GradientButton from "../components/GradientButton";
import Popup from "../components/Popup";


export default function Entry ({isMobile})
{
  const [pin, setPin] = useState('');
  const [ig, setIg] = useState('');
  const [tos, setTos] = useState(false);

  const [popupPIN, setPopupPIN] = useState(false);
  const [popupServ, setPopupServ] = useState(false);
  const [popupNameLen, setPopupNameLen] = useState(false);

  const tbxWrapper = {
    width: isMobile ? '296px' : '390px',
    height: isMobile ? '58px' : '68px',
    marginTop: isMobile ? '40px' : '30px'
  };

  const tbxInner = {
    width: '100%',
    height: '58px',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'Sansation Light',
    fontSize: '20px',
    fontWeight: '300px',
    '::placeholder': {
      color: '#fff'
    }
  };

  const onSubmit = function (e)
  {
    e.preventDefault();

    if (!tos) {
      return;
    }

    if (ig.length > 24) {
      setPopupNameLen(true);
      return;
    }

    if (ig.trim().length <= 0 || pin.trim().length <= 0) {
      return;
    }

    axios.post(`/api/session`, {
      pin: pin.trim(), ig: ig.trim()
    }).then((resp) => {
      console.log(resp);
      const token = resp.data.token;
      localStorage.setItem("auth_key", token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      window.location.hash = '#/lobby';
    }).catch((err) => {
      console.log(err);
      if (err.response.status == 403) {
        setPopupPIN(true)
      } else {
        setPopupServ(true)
      }
    });

  }

  return (
    <>
      <Popup open={popupPIN} setOpen={setPopupPIN} title="Błędny PIN" descr="Podano nieprawidłowy pin do konkursu" />
      <Popup open={popupServ} setOpen={setPopupServ} title="Wewnętrzny błąd serwera" descr="Wystąpił nieoczekiwany problem. Spróbuj ponownie za parę minut" />
      <Popup open={popupNameLen} setOpen={setPopupNameLen} title="Zbyt długa nazwa" descr="Podana nazwa użytkownika nie może przekraczać 24 znaków" />

      <div style={{
        backgroundColor: '#111',
        backgroundImage: `url(/bg_entry${isMobile ? '_mob' : ''}.png)`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <form style={{
          width: isMobile ? '296px' : '390px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }} onSubmit={onSubmit}>
          <img src="/logo.svg" style={{width: isMobile ? '231px' : '311px'}}/>
          {/*<div style={{*/}
          {/*  width: isMobile ? '265px' : '332px',*/}
          {/*  marginTop: '65.5px',*/}
          {/*  textAlign: 'center',*/}
          {/*  color: '#fff',*/}
          {/*  fontFamily: 'Sansation Light',*/}
          {/*  fontSize: '20px',*/}
          {/*  fontWeight: 300*/}
          {/*}}>*/}
          {/*  Jesteś tutaj, bo masz szansę wygrać samochód swoich marzeń.*/}
          {/*</div>*/}

          <div style={{
            backgroundImage: `url(/tbx_pin${isMobile ? '_mob' : ''}.png)`, ...tbxWrapper
          }}>
            <input value={pin} onChange={(e) => setPin(e.target.value)} style={tbxInner} type="text"
                   placeholder="podaj PIN"/>
          </div>

          <div style={{
            backgroundImage: `url(/tbx_nick${isMobile ? '_mob' : ''}.png)`, ...tbxWrapper
          }}>
            <input value={ig} onChange={(e) => setIg(e.target.value)} style={tbxInner} type="text"
                   placeholder="@nazwa z instagrama"/>
          </div>

          <div style={{
            display: 'flex',
            gap: '14px',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontFamily: 'Sansation Light',
            fontSize: '14px',
            fontWeight: 300,
            marginTop: '17px'
          }}>
            <img
              src={tos ? "/checked.svg" : "/unchecked.svg"}
              style={{height: '21px', cursor: 'pointer'}}
              onClick={(e) => setTos(!tos)}
            />
            Oświadczam, że zapoznałem/am się z warunkami konkursu.
          </div>

          <button style={{
            marginTop: '30px',
            width: '241px',
            height: '68px',
            fontFamily: 'Sansation',
            fontWeight: 700,
            color: '#000',
            fontSize: '20px',
            borderRadius: '52px',
            background: tos && ig.length != 0 && pin.length != 0 ? 'linear-gradient(82deg, #4c0197 -13.64%, #fddf01 92.08%)' : '#343434',
            border: 'none',
            outline: 'none',
            cursor: 'pointer'
          }}>
            Wchodzę
          </button>
        </form>
      </div>
    </>
  )
}