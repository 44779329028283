import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";

export default function Wyniki ({ isMobile })
{
	const { score, time } = useParams();

	const convertTimestamp = function (ts)
	{
		const conv = new Date(parseInt(ts));
		return `${conv.getMinutes()}m ${conv.getUTCSeconds()}.${conv.getUTCMilliseconds()}sek`
	}

	const userTime = convertTimestamp(time);

	const [awaitingResultsMessage, setAwaitingResultsMessage] = useState("Pobieranie informacji z serwera...");

	const [leaderboard, setLeaderboard] = useState([]);

	const fetchResults = function ()
	{
		setAwaitingResultsMessage("Trwa obliczanie wyników...");
		axios.get("/api/leaderboard").then((resp) => {
			setAwaitingResultsMessage(null);
			setLeaderboard(resp.data);
		}).catch((err) => {
			setTimeout(() => {
				fetchResults();
			}, 5000);
		})
	}

	useEffect(() => {
		fetchLobby();
	}, []);

	const sortLeaderboard = function (a, b)
	{
		if (b.score !== a.score) {
			return b.score - a.score;
		}
		return a.time - b.time;
	}

	const fetchLobby = function ()
	{
		axios.get('/api/lobby').then((resp) => {
			const ending = new Date(resp.data.ending);
			const intv = setInterval(() => {
				const current = (new Date());
				const offTime = 45;
				const diff =  (ending.getTime() / 1000 + offTime - current.getTime() / 1000);
				if (diff < 0) {
					/**
					 * Oczekiwanie na ranking zakonczylo sie, wiec
					 * mozemy atakowac serwer o odpowiedz z leaderboardem
					 */
					clearInterval(intv);
					fetchResults();
					return;
				}
				const conv = new Date(parseInt(diff) * 1000);
				const hrs = conv.getUTCHours().toString().padStart(2, '0');
				const mns = conv.getUTCMinutes().toString().padStart(2, '0');
				const sec = conv.getUTCSeconds().toString().padStart(2, '0');
				setAwaitingResultsMessage(`Ranking zostanie odsłonięty za ${hrs}h ${mns}m ${sec}s`);
			}, 1000);
		}).catch((err) => {
			alert(`Błąd #lobby ${JSON.stringify(err)}`);
		})
	}

	return (
		<div style={{
			width: '100vw',
			height: '100vh',
			backgroundColor: '#111',
			backgroundImage: `url(bg_lobby${isMobile ? '_mob' : ''}.png)`,
			backgroundPosition: 'center bottom',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			// overflow: isMobile ? 'scroll' : 'hidden'
		}}>
			<div style={{overflow: 'auto', boxSizing: 'border-box', paddingBottom: '120px'}}>
				<div style={isMobile ? {
					width: '100vw',
					paddingBottom: '75px'
				} : {
					width: '100vw',
				}}>
					<div style={isMobile ? {
						width: '100%', textAlign: 'center'
					} : {
						width: '90%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						margin: "auto"
					}}>
						<img src="/logo.svg" style={{marginTop: isMobile ? '95px' : '75px', width: isMobile ? '231px' : '311px'}}/>
						{!isMobile && <img src="/koniec.svg"/>}
					</div>
					<div style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						textAlign: 'center'
					}}>
						<img src="/checkmark.png" style={{width: '104px', marginTop: '38px'}}/>
						<div style={{
							fontFamily: 'Sansation', fontSize: isMobile ? '20px' : '32px', fontWeight: 700, color: '#fff',
							marginTop: '24px',
							width: isMobile ? '291px' : '100%'
						}}>
							{score ? 'Test został ukończony pomyślnie' : 'Test zakończył się'}
						</div>
						<div style={{
							fontFamily: 'Sansation Light', fontSize: isMobile ? '16px' : '24px', fontWeight: 300, color: '#fff',
							marginTop: '25px'
						}}>
							{score ? `Twój wynik: ${score}/10, czas: ${userTime}` : 'Sprawdź ranking'}
						</div>


						<div style={{
							fontFamily: 'Sansation Light', fontSize: isMobile ? '16px' : '24px', fontWeight: 300, color: '#fff',
							marginTop: '25px'
						}}>
							{awaitingResultsMessage}
						</div>
						<div style={{
							fontFamily: 'Sansation Light',
							fontSize: isMobile ? '14px' : '20px',
							fontWeight: 600,
							color: '#fff',
							textDecoration: 'underline',
							marginTop: '16px'
						}}>
							Nie odświeżaj strony!
						</div>


						<div style={{
							display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', gap: '10px 30px',
							width: isMobile ? '350px' : '1012px', marginTop: '56px'
						}}>
							{leaderboard.sort(sortLeaderboard).map((slice, key) => (
								<div style={{
									width: '489px', borderRadius: '5px', backgroundColor: '#242424', border: '1px solid #494949',
									height: '36px', display: "flex", justifyContent: 'space-between', alignItems: 'center',
									padding: '0px 25px 0px 12px', boxSizing: 'border-box'
								}}>
									<div style={{display: 'flex', gap: '22px'}}>
										<div style={{
											fontFamily: 'Sansation Light',
											fontSize: isMobile ? '14px' : '16px',
											fontWeight: 300,
											color: '#fff'
										}}>{isMobile ? 'TOP' : 'MIEJSCE'} {key + 1}:
										</div>
										<div style={{
											fontFamily: 'Sansation', fontSize: isMobile ? '14px' : '16px', fontWeight: 400, color: '#fff'
										}}>@{slice.ig} <span style={{fontWeight: 200}}>- {slice.score}/10</span></div>
									</div>
									<div style={{
										fontFamily: 'Sansation Light',
										fontSize: isMobile ? '14px' : '16px',
										fontWeight: 300,
										color: '#fddf01'
									}}> {convertTimestamp(slice.time)}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}