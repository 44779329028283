import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  border-radius: 52px;
  padding: 2px;
  background: linear-gradient(251.99deg, #4C0197 19.98%, #FDDF01 72.98%);
  ${(props) => props.disabled && 'opacity: 0.3;'}
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 71px;
  width: 100%;
  border: none;
  border-radius: 52px;
  outline: none;
  background-color: ${(props) => (props.disabled ? '#343434' : '#111111')};
  transition: background-color 0.2s ease-in-out;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-family: Sansation;
  font-weight: 300;

  &:hover {
    color: ${(props) => (props.disabled ? null : '#FDDF01')};
  }

  &:active {
    background: ${(props) => (props.disabled ? null : 'linear-gradient(252deg, #4C0197 19.98%, #FDDF01 72.98%)')};
    color: ${(props) => (props.disabled ? null : '#111111')};
  }
`;


export default function GradientButton ({ children, disabled, containerStyle, buttonStyle, ...props })
{
  return (
    <Container style={containerStyle} disabled={disabled}>
      <Button style={buttonStyle} disabled={disabled} {...props}>
        {children}
      </Button>
    </Container>
  );
};
